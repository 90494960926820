.format--card {
  $this: &;
  position: relative;
  width: $format-card-width;
  height: $format-card-height;
  float: left;

  @at-root .is-servus & {
    width: $format-card-width * 2;

    &__inner {
      &::before {
        background-color: $rb-iron;
      }

      &__copy {
        span {
          color: $search-field-color;

          img {
            width: s(39px);
            height: s(39px);
            margin-top: s(-5px);
            margin-right: s(18px);
            margin-left: s(-18px);
          }
        }
      }
    }

    &.is-focused {
      #{$this}__inner {
        &__copy {
          span {
            color: $pure-white;
          }
        }
      }
    }
  }

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: $format-card-offset-y $format-card-offset-x;
    text-align: center;
    z-index: 1;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: $format-card-offset-x;
      right: $format-card-offset-y;
      bottom: $format-card-offset-x;
      left: $format-card-offset-y;
      background-color: $rb-new-back;
      border-radius: $card-border-radius;
      transition: $card-transition;
      z-index: 0;
      // will-change: top, right, bottom, left, background-color;
    }

    &__copy {
      display: table;
      width: 100%;
      height: 100%;

      span {
        display: table-cell;
        position: relative;
        vertical-align: middle;
        text-align: center;
        text-transform: uppercase;
        font-size: s(28px);
        line-height: s(56px);
        font-weight: 600;
        color: $rb-iron;
        transition: $card-transition;
        // will-change: color;

        img {
          display: inline;
          width: s(56px);
          height: s(56px);
          margin-right: s(12px);
          margin-left: s(-12px);
          // object-fit: contain;
          // object-position: center;
          vertical-align: middle;
        }
      }
    }

  }

  &.is-focused {
    #{$this}__inner {
      &::before {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $rb-red;
      }
    }
  }
}
