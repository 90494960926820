.captions-settings-helptext {
    position: absolute;
    width: s(760px);
    left: s(800px);
    top: s(112px + 430px + 70px);
    color:$rb-white;
    text-align: center;
    font-family: rb-medium;
    font-size: s(30px);
}

.captions-container {
    div * {
        font-family: inherit;
    }
}
