#search {
    position: absolute;

    .button-list-wrapper{
        width: s(672px);
        height: s(312px);
        left: s(900px);
        top: s(150px);
        position: absolute;
        overflow: hidden;
    }

    .search__input-section {
        position: relative;
        top: s(40px);
    }

    .list-panel {
        margin-left: 0;
    }

    .button-list{
        position: relative;
    }
}
