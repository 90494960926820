.video-playback-error-overlay {
  position: relative;
  z-index: 160;
  > img {
    width: s(1920px);
    height: s(1080px);
    position: absolute;
  }

  &__text {
    position: absolute;
    top: s(450px);
    width: s(1920px);
    color: $video-playback-error-overlay-color;
    font-size: s(31px);
    text-align: center;
  }

  &__icon{
    position: absolute;
    top: s(338px);
    width: s(1920px);
    text-align: center;
  }

  &__reload-btn{
    position: absolute;
    top: s(636px);
    width: s(1920px);
    text-align: center;

    .regular-button {
      display: inline-block;
    }
  }
}




