.menu {
  position: absolute;
  top: s(0px);
  background: $menu-gradient;
  background:  $menu-gradient-webkit;
  width: s(2000px);
  height: s(144px);
  transition: transform 200ms;
  // will-change: transform;

  &.is-collapsed {
    transform: translateY(s(-144px));
  }

  &.whiteTheme {
    .menu-logo {
      top: s(48px);
      width: s(151px);
      height: s(48px);
      background-size: s(151px) s(48px);
      background-image: $menu-logo-image--whiteTheme;
    }

    .menu-item__title {
      color: $white-faded;
    }

    .menu-item--active {
      .menu-item__title {
        color: $rb-alabaster;
      }
    }
    .menu-item__title--brighter {
      color: $rb-iron;
    }
  }

  .menu-logo {
    position: absolute;
    top: s(40px);
    left: $main-content-left-offset;
    width: s(176px);
    height: s(72px);
    z-index: 1000;
    pointer-events: none;
    background-image: $menu-logo-image;
    background-repeat: no-repeat;
    background-size: s(176px) s(72px);

    img {
      width: 100%;
    }
  }

  .menu-items {
    position: absolute;
    top: 0;
    left: s(520px);
    margin: 0;
    padding: 0;
    height: s(144px);
    width: s(1500px);
    white-space: nowrap;
  }
}

