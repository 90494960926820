.text-page {
  position: absolute;
  top: s(-40px);
  left: s(-300px);
  height: s(1180px);
  width: s(2120px);
  background-color: $text-page-background;
  
  a:link {
    text-decoration: none;
    color: $text-page-text-color;
  }

  z-index: 200;
  > .text-page__content {
    position: absolute;
    margin-top: s(20px);
    left: s(350px);
    top: s(200px);
    line-height: s(40px);
    width: s(820px);
    height: s(850px);
    font-size: s(24px);
    color: $text-page-text-color;
    padding:s(15px);
    overflow:scroll;

    &.is-focused
    {
      color: $text-page-text-color;

      &::-webkit-scrollbar-thumb {
        background: white;
      }
    }
  }

  /* Scrollbar styles */
  ::-webkit-scrollbar {
    width: s(3px);
    height: 0px;
  }

  ::-webkit-scrollbar-track {

    background-color: grey;
  }

  ::-webkit-scrollbar-thumb {
    background: darkgrey;
    border-radius: 0px;
  }

  &--consumption {
    position: static;
    background-color: transparent;
    width: s(1344px);
    height: s(600px);
    min-height: s(600px);

    > .text-page__content {
      position: static;
      width: s(1344px);
      height: s(600px);
      min-height: s(600px);
      padding: 0;
      margin: 0;
    }
  }

}
.text-page__title{
    position: absolute;
    font-size: s(48px);
    color: $text-page-text-color;
    margin-top: s(120px);
    left: s(370px);
  }

.text-page__aside {
  position: absolute; left: s(150px);

  span{
    position:absolute; top:s(515px); left:s(100px);
    color: $text-page-text-color;
    font-size:s(30px);
  }
}
