.schedule-list {
  &__header {
    height: s(144px);

    h1 {
      display: block;
      margin: 0 0 s(40px) 0;
      color: $rb-iron;
      font-family: rb-bold;
      font-size: s(40px);
      line-height:s(48px);
      height: s(48px);
      position: absolute;
      top: s(64px);
      left: s(16px);
    }
  }

  &__list {
    position: relative;
    width: s(1920px);
    height: $playlist-card-height;
  }

  &__list-wrapper {
    position: absolute;
    top: 0;
    width: 1000000px;
    height: $playlist-card-height;
    // will-change: transform;

    &--active {
      transition: transform 150ms ease;
    }
  }

}

