.cornerbug {
  position: absolute;
  top: s(80px);
  right: s(100px);
  width: s(140px);
  height: s(78px);
  z-index: 1000;

  background-size: cover;
  img {
    width: 100%;
  }

  @-webkit-keyframes cbFadeIn {
    0%,
    25% {
      opacity: 0;
    }
    45%,
    65% {
      opacity: 0;
    }
    65%,
    75% {
      opacity: 0.8;
    }
    75%,
    100% {
      opacity: 0.8;
    }
  }

  @-moz-keyframes cbFadeIn {
    0%,
    25% {
      opacity: 0;
    }
    45%,
    65% {
      opacity: 0;
    }
    65%,
    75% {
      opacity: 0.8;
    }
    75%,
    100% {
      opacity: 0.8;
    }
  }

  @-o-keyframes cbFadeIn {
    0%,
    25% {
      opacity: 0;
    }
    45%,
    65% {
      opacity: 0;
    }
    65%,
    75% {
      opacity: 0.8;
    }
    75%,
    100% {
      opacity: 0.8;
    }
  }

  @keyframes cbFadeIn {
    0%,
    25% {
      opacity: 0;
    }
    45%,
    65% {
      opacity: 0;
    }
    65%,
    75% {
      opacity: 0.8;
    }
    75%,
    100% {
      opacity: 0.8;
    }
  }

  @-webkit-keyframes cbFadeOut {
    0%,
    25% {
      opacity: 0.8;
    }
    45%,
    65% {
      opacity: 0.8;
    }
    65%,
    75% {
      opacity: 0;
    }
    75%,
    100% {
      opacity: 0;
    }
  }

  @-moz-keyframes cbFadeOut {
    0%,
    25% {
      opacity: 0;
    }
    45%,
    65% {
      opacity: 0;
    }
    65%,
    75% {
      opacity: 0.8;
    }
    75%,
    100% {
      opacity: 0.8;
    }
  }

  @-o-keyframes cbFadeOut {
    0%,
    25% {
      opacity: 0;
    }
    45%,
    65% {
      opacity: 0;
    }
    65%,
    75% {
      opacity: 0.8;
    }
    75%,
    100% {
      opacity: 0.8;
    }
  }

  @keyframes cbFadeOut {
    0%,
    25% {
      opacity: 0.8;
    }
    45%,
    65% {
      opacity: 0.8;
    }
    65%,
    75% {
      opacity: 0;
    }
    75%,
    100% {
      opacity: 0;
    }
  }

  $cb-animation-time: 9000ms;
  &.is-fading-out {
    -webkit-animation-name: cbFadeOut;
    -moz-animation: cbFadeOut;
    -o-animation: cbFadeOut;
    animation: cbFadeOut;
    -webkit-animation-duration: $cb-animation-time;
    -moz-animation-duration: $cb-animation-time;
    -o-animation-duration: $cb-animation-time;
    animation-duration: $cb-animation-time;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -o-animation-iteration-count: 1;
    animation-iteration-count: 1;
  }

  &.is-fading-in {
    -webkit-animation-name: cbFadeIn;
    -moz-animation: cbFadeIn;
    -o-animation: cbFadeIn;
    animation: cbFadeIn;
    -webkit-animation-duration: $cb-animation-time;
    -moz-animation-duration: $cb-animation-time;
    -o-animation-duration: $cb-animation-time;
    animation-duration: $cb-animation-time;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -o-animation-iteration-count: 1;
    animation-iteration-count: 1;
  }

  &--color {
    opacity: 0;
  }

  &--mono {
    opacity: 0.5;
  }

  @at-root .is-servus & {
    position: absolute;
    top: s(30px);
    right: s(30px);
    width: s(235px);
    height: s(75px);
  }
}
