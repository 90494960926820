$module-width: s(660px);
$module-spacing-from-page-border: s(110px);

.page-status-module-test-screen {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: s(1920px);
  height: s(1080px);
  background: url("../images/event-page-status-live.jpg");

  background-size: 100% 100%;
}

.page-status-module {
  $this: &;
  position: absolute;
  top: s(80px);
  right: s(0px);
  padding-left: s(30px);
  padding-bottom: s(30px);
  box-sizing: border-box;
  width: $module-width;
  background-color: rgba($pure-black ,0.5);

  &--live {
    #{$this}__label {
      color: $label-bg--live;
    }
  }

  &--delayed {
    #{$this}__label {
      color: $rb-yellow;
    }
  }

  &--canceled {
    #{$this}__label {
      color: $rb-orange;
    }
  }

  &__label {
    display: inline-block;
    text-transform: uppercase;
    font-family: rb-bold;
    font-weight: bold;
    font-size: s(40px);
    line-height: s(46px);
    margin-top: s(30px);
    margin-bottom: s(5px);
    width: s(530px);

    height: s(46px);
    color: $rb_white;
  }

  &__about_to_start {
    display: inline-block;
    color: $rb_white;
    font-size: s(40px);
    line-height: s(46px);
    height: s(46px);
    font-family: rb-bold;
    margin-bottom: s(5px);
    margin-top: s(30px);
  }

  &__title {
    margin-bottom: s(7px);
    color: $rb_white;
    font-size: s(32px);
    line-height: s(36px);
  }

  &__date {
    margin-top: s(30px);
    color: $rb_white;
    font-weight: 900;
    font-size: s(60px);
    line-height: s(72px);
    font-family: rb-heavy1;
  }

  &__text {
    color: $rb_white;
    font-size: s(30px);
    line-height: s(37px);
    width: s(490px);
    font-family: rb-medium;
    font-weight: 500;
    overflow: hidden;
  }

  &__countdown {
    margin-top: s(20px);
    margin-bottom: 0px;
    color: $rb_white;
    font-weight: 900;
    font-size: s(60px);
    line-height: s(73px);
    width: s(700px);
    font-family: BullMono-Heavy1 !important;
    .non-mono {
      font-family: rb-heavy1 !important;
    }
  }

  &__countdown-block {
    display: inline-block;
    margin-left: s(21px);

    &:first-child {
      margin-left: 0;
    }

    span {
      font-family: BullMono-Heavy1 !important;
    }
  }
}
