.cc {
  width: s(1920px);
  height: s(1080px);
  margin: s(15px);
  position: relative;
  overflow: hidden;
}

.defaultStyle{
  position: absolute;
  bottom: 10px;
  text-align: center;
}

.cueDirUniWrapper{
  padding: 5pt;
}

#captions {
  width: s(1920px);
  height: s(1080px);
  margin: 0;

  position: absolute;
  top: 0; 
  left: 0;
  pointer-events:none;
  overflow: hidden;
}

//WebVTT
#captionWindow {
    /* work around tizen positioning bug */
    left: 50% !important;
    right: auto !important;
    top: auto !important;
    bottom: s(120px) !important;

    /* get cues centering properly */
    display: block !important;
    position: absolute !important;
    height: auto !important;

    margin: 0 auto;
    padding: 10px;

    transform: translateX(-50%);

    > div {
      padding-left: 10px;
      padding-right: 10px;
    }
}
