.universalPlayer {
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;
  object,video{
    width: 100% !important;
    height: 100% !important;
  }

  .dashPlayer {
    top:0px;
    position:absolute;
    left:0px;
  }

  .html5HLSPlayer {
    top:0px;
    position:absolute;
    left:0px;
  }

  #ad-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
  }
}