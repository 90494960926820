$menu-item-height: s(96px);
$menu-item-icon-height: s(75px);

.menu-item {
  margin-left: s(128px);
  display: inline-block;
  position: relative;
  top: s(56px);

  &:first-child {
    margin-left: 0;
  }

  &__title {
    color: $menuItem-color;
    text-transform: uppercase;
    font-weight: 600;
    font-size: s(26px);
    line-height: s(32px);
    position: relative;
    transition: color 250ms ease-in-out;
    // will-change: color;

      &::after {
        position: absolute;
        bottom: s(-16px);
        width: 100%;
        display: block;
        content: '';
        border-bottom: solid $menuItem-border-bottom-height transparent;
        transform: scaleX(0);
        transition: transform 250ms ease-in-out, border-bottom-color 250ms ease-in-out;
        // will-change: transform, border-bottom-color;
      }

    .menu-item--active & {
      color: $menuItem-color--active;

      &::after {
        transform: scaleX(1);
        border-bottom-color: $menuItem-border-bottom--active;
      }
    }

    .menu-item--inactive & {
      &::after {
        transform: scaleX(1);
        border-bottom-color: $menuItem-border-bottom;
      }
    }
  }
  // restore menu title color on white background

  @at-root .is-servus & {
    top: s(48px);
  }
}

