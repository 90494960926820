
@font-face {
  font-family: 'BullMono-Medium';
  font-style: normal;
  font-weight: normal;
    src: url("../fonts/mono/BullMono-Medium.eot");
    src: url("../fonts/mono/BullMono-Medium.eot") format("embedded-opentype"),
       url("../fonts/mono/BullMono-Medium.ttf") format("truetype");
}
@font-face {
  font-family: 'BullMono-Heavy1';
  font-style: normal;
  font-weight: normal;
    src: url("../fonts/mono/BullMono-Heavy1.eot");
    src: url("../fonts/mono/BullMono-Heavy1.eot") format("embedded-opentype"),
       url("../fonts/mono/BullMono-Heavy1.ttf") format("truetype");
}

@font-face {
  font-family: 'BullMono-Bold';
  font-style: normal;
  font-weight: normal;
    src: url("../fonts/mono/BullMono-Bold.eot");
    src: url("../fonts/mono/BullMono-Bold.eot") format("embedded-opentype"),
       url("../fonts/mono/BullMono-Bold.ttf") format("truetype");
}
@font-face {
  font-family: 'BullMono-Regular';
  font-style: normal;
  font-weight: normal;
    src: url("../fonts/mono/BullMono-Regular.eot");
    src: url("../fonts/mono/BullMono-Regular.eot") format("embedded-opentype"),
       url("../fonts/mono/BullMono-Regular.ttf") format("truetype");
}
@font-face {
  font-family: 'Bull-Regular';
  font-style: normal;
  font-weight: normal;
    src: url("../fonts/Bull-Regular.eot");
    src: url("../fonts/Bull-Regular.eot") format("embedded-opentype"),
       url("../fonts/Bull-Regular.ttf") format("truetype");
}

/* closed caption typefaces. */
/* per: http://www.ecfr.gov/cgi-bin/text-idx?SID=63b331368f808a79d92ea8dc7b8f7516&mc=true&node=se47.4.79_1102&rgn=div8
   section (m), we require normal and italic for all typefaces */

/* monospace sans-serif */
@font-face {
  font-family: 'UbuntuMono';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/cc/UbuntuMono-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'UbuntuMono';
  font-style: italic;
  font-weight: normal;
  src: url("../fonts/cc/UbuntuMono-Italic.ttf") format("truetype");
}

@font-face {
  font-family: 'LinuxKorean';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/cc/NanumSquareB.ttf") format("truetype");
}

@font-face {
  font-family: 'LinuxChinese';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/cc/UnDinaru.ttf") format("truetype");
}

/* smallcaps */
@font-face {
  font-family: 'PlayfairDisplaySC';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/cc/PlayfairDisplaySC-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'PlayfairDisplaySC';
  font-style: italic;
  font-weight: normal;
  src: url("../fonts/cc/PlayfairDisplaySC-Italic.ttf") format("truetype");
}


/* serif */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/cc/Merriweather-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: normal;
  src: url("../fonts/cc/Merriweather-Italic.ttf") format("truetype");
}


/* monospace serif */
@font-face {
  font-family: 'CourierPrime';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/cc/CourierPrime-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'CourierPrime';
  font-style: italic;
  font-weight: normal;
  src: url("../fonts/cc/CourierPrime-Italic.ttf") format("truetype");
}


/* casual */
@font-face {
  font-family: 'Pangolin';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/cc/Pangolin-Regular.ttf") format("truetype");
}
/* XXX we are missing the italic face for casual */

/* cursive */
@font-face {
  font-family: 'DancingScript';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/cc/DancingScript-Regular.ttf") format("truetype");
}

/* XXX we are missing the italic face for cursive */
