.search_field {
  text-transform: uppercase;
  width: s(1630px);
  height: s(100px);
  line-height: s(100px);
  color: $rb-white;
  margin-bottom: s(45px);
  padding: 0 s(40px);
  position: relative;
  background: $search-field-background;
  font-size: 36px;
  margin-left: s(8px);
  border-radius: $card-border-radius;

  &.is-active {
    color: $rb-grey-300;
    &:before {
      background: $rb-grey-100;
    }
    &.is-blink > span {
      border-color: $rb-red;
    }
    //> span {
    //  animation: blink .5s step-end infinite alternate;
    //}
  }

  &.is-focused {
    height: s(120px);
    margin: s(-10px) 0 s(50px) 0;
    background: $rb-white;
    color: $rb-grey-100;

    > span {
      position: absolute;
      display: inline-block;
      height: s(40px);
      line-height: s(40px);
      top: s(40px);
      border-right: s(5px) solid transparent;
      padding-right: s(2px);
      font-size: s(36px);

      //animation: blink .5s step-end infinite alternate;
    }
    > #searchFieldIcon {
      margin-top: s(25px);
      margin-right: s(70px);
    }
  }

  &.is-focused {
    &:after {
      content: 'Edit';
      position: absolute;
      line-height: s(100px);
      right: s(36px);
      font-size: s(28px);
    }
  }

  &.is-focused:after{
    line-height: s(120px);
  }

  > span {
    font-weight: 300;
    position: absolute;
    display: inline-block;
    height: s(40px);
    line-height: s(40px);
    top: s(30px);
    border-right: s(5px) solid transparent;
    padding-right: s(2px);
    font-size: s(38px);
    color: $search-field-color;

    //animation: blink .5s step-end infinite alternate;
  }


}

@keyframes blink {
  50% {
    border-color: $rb-red
  }
}

.no-results {
  font-size: $menu-title;
  font-weight: 600;
  color: white;
  margin-top: 60px;
  > em {
    font-weight: 700;
    font-style: normal;
  }
}

#searchFieldIcon {
  float: right;
  margin-top: s(15px);
  height: s(64px);
  width: s(64px);
}
