$padding: 120px;

.signin-prompt {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: s($padding + 72px) s($padding) 0;
  background-color: $rb-blue-charcoal;
  background-image: url(../images/optional-signup.jpg);
  background-size: 100% 100%;
  color: $rb-alabaster;
  z-index: 9999;

  &__content {
    &::before,
    &::after {
      content: "";
      display: table;
      width: 100%;
    }

    &::after {
      clear: both;
    }

    &__left {
      float: left;
      width: 66.66%;
      padding-right: s($padding);
    }

    &__right {
      float: right;
      width: 33.34%;
    }
  }

  h1 {
    width: s(673px);
    font-size: s(60px);
    line-height: s(90px);
    margin: s(80px) 0 s(48px);
  }

  p {
    font-size: s(20px);
    line-height: s(30px);
    margin: s(16px) 0;
  }

  h3 {
    font-size: s(40px);
    line-height: s(60px);
    margin: s(16px) 0;
    font-weight: normal;
  }

  &-el {
    &--logo {
      position: absolute;
      top: s($padding);
      left: s($padding);
      width: s(176px);
      height: s(72px);
      background-image: $menu-logo-image;
      background-repeat: no-repeat;
      background-size: s(176px) s(72px);
      pointer-events: none;
    }

    &--bullet {
      position: relative;
      margin: s(48px) 0;
      padding-left: s(120px);
      line-height: s(80px);

      &__icon {
        display: block;
        position: absolute;
        width: s(80px);
        height: s(80px);
        top: 0;
        left: 0;
      }
    }

    &--qr {
      display: block;
      width: s(300px);
      height: s(300px);
      border-radius: s(15px);
      margin-bottom: s(32px);
      opacity: 0;
      transition: opacity 100ms;
      // will-change: opacity;

      &.loaded {
        opacity: 1;
      }
    }

    &--token {
      letter-spacing: 0.25em;
    }

    &--buttons {
      margin-top: s(60px);

      &::before,
      &::after {
        content: "";
        display: table;
        width: 100%;
      }

      &::after {
        clear: both;
      }

      &-left {
        float: left;
      }

      &-right {
        float: right;
      }

      .regular-button {
        max-width: s(275px);

        &__inner {
          padding-right: s(12px);
          padding-left: s(12px);
        }
      }
    }
  }
  @at-root .is-servus & {
    background-color: $rb-alabaster;
    background-image: none;
    color: $servus-black;

    h1 {
      margin-top: 0;
      font-weight: 700 !important;
    }

    p {
      font-size: s(24px);
      line-height: s(36px);
      color: rgba($servus-black, 0.6);
    }

    h3 {
      font-weight: 700 !important;
      word-break: break-word;
    }
    &-el {
      &--logo {
        top: s(40px);
        left: s(100px);
      }

      &--bullet {
        line-height: s(80px);
      }

      &--url {
        margin: s(40px) 0;
        line-height: s(48px);
        text-transform: lowercase;
      }

      &--token {
        font-weight: 700 !important;
        font-size: s(48px);
        line-height: s(48px);
        letter-spacing: 0.25em;
      }

      &--buttons {
        margin-top: s(78px);

        &-left {
          margin-right: s(6px);
        }

        &-right {
          float: left;
        }

        .regular-button {
          max-width: s(310px);

          &__inner {
            text-transform: uppercase;
          }
        }
      }
    }

  }
}