#buffering {
  position: absolute;
  top: 0;
  left: 0;
  width: s(1920px);
  height: s(1080px);
  z-index: 2;
  pointer-events: none;
}

.bufferingBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: s(1920px);
  height: s(1080px);
  background-color: red
}

.loader {
  position: relative;
  margin: s(490px) auto 0;
  width: s(170px);
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.loader-gif-div {
  position: relative;
  margin: s(300px) auto;
  width: s(360px);
}

.loader-gif {
  width: s(360px);
}

.circular {
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  animation: rotate 2s linear infinite;

  @at-root .is-low-resolution & {
    animation: rotate 2s linear infinite !important;
  }
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke: $rb-iron;
  stroke-linecap: square;
  animation: dash 1.5s linear infinite;
  @at-root .is-low-resolution & {
    animation: dash 1.5s linear infinite !important;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

.spinner-lowFPS {
  position: relative;
  margin: s(532px) auto;

  width: s(420px);
  height: s(24px);
  background-size: s(2100px) s(24px) !important;
  background-repeat: no-repeat;
  animation: playSpinner-lowFPS 2.5s steps(5) infinite;
  @at-root .is-low-resolution & {
    animation: playSpinner-lowFPS 2.5s steps(5) infinite !important;
  }
}

@keyframes playSpinner-lowFPS {
  100% { background-position: s(-2100px); }
}
