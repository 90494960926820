// GENERAL
@import "include/normalize";
@import "include/config";
@import "include/fonts";
// LABELS
@import "include/labels";
@import "include/captions";
// CORNERBUG
@import "../js/components/cornerbug/cornerbug";
// BUFFERING
@import "../js/components/buffering/buffering";
// BUTTONS
@import "../js/components/buttons/buttons";
// HINTS
@import "../js/components/hint/hint";
// CHANNEL TITLE
@import "../js/components/channel-title/channel-title";
// DIALOG
@import "../js/components/dialogs/dialog";
@import '../js/components/signin-prompt/signin-prompt';
@import '../js/components/sunset-message/sunset-message';
@import '../js/components/privacy-consent-prompt/privacy-consent-prompt';
@import "../js/components/home/home";
// CARDS
@import "../js/components/playlist/playlist";
@import "../js/components/card/card";

@import "../js/components/cc/cc";
@import "../js/components/menu/menu";
@import "../js/components/menu-item/menu-item";
@import "../js/components/grid-page/grid-page";
// LANDING PAGES AND SUBPAGES
@import "../js/components/subpages/subpages";
@import "../js/components/landing-page/landing-page";
@import "../js/components/landing-page-details/landing-page-details";
// CONTEXTUAL AREA
@import "../js/components/contextual/contextual";
@import "../js/components/player-controls/player-controls";
@import "../js/components/tabs/tabs";
@import "../js/components/tab/tab";
@import "../js/components/language-menu/language-menu";
// SEARCH
@import "../js/components/search/search";
@import "../js/components/search-field/search-field";
@import "../js/components/search-keyboard/search-keyboard";
// SETTINGS
@import "../js/components/settings/settings";
@import "../js/components/settings-menus/settings-menus";
@import "../js/components/live-channels/live-channels";
@import "../js/components/captions-preview/captions-preview";

// PROGRESS BAR
@import "../js/components/progress-bar/progress-bar";


// VIDEO PLAYER
@import "../js/components/video-player/universal-player";
// TEST SCREEN
@import "../js/components/consumption/consumption";
@import "../js/components/nerd-mode/nerd-mode";
// EMPTY STATES
@import "../js/components/empty-state/empty-state";
// WHATS NEXT
@import "../js/components/whats-next/whats-next";
// EVENT STATUS MODULE
@import "../js/components/page-status-module/page-status-module";
@import "../js/components/countdown/countdown";

@import '../js/components/card-row/card-row';
@import '../js/components/featured-card/featured-card';
@import '../js/components/featured-card-small/featured-card-small';
@import '../js/components/channel-card/channel-card';
@import '../js/components/page-card/page-card';
@import '../js/components/universal-card/universal-card';
@import '../js/components/live-card/live-card';
@import '../js/components/playlist-card/playlist-card';
@import '../js/components/video-card/video-card';
@import '../js/components/format-card/format-card';
@import '../js/components/schedule-card/schedule-card';
@import '../js/components/schedule-list/schedule-list';
@import '../js/components/low-fps-intro/low-fps-intro';
@import '../js/components/text-page/text-page';
@import '../js/components/page-buttons/page-buttons';
@import '../js/components/small-button/small-button';
@import '../js/components/card-progress-bar/card-progress-bar';
@import '../js/components/video-playback-error-overlay/video-playback-error-overlay';
@import '../js/components/thumbnail-preview/thumbnail-preview';
@import '../js/components/ads-countdown/ads-countdown';

body {
  background-color: $pure-black;
  width: s(1920px);
  height: s(1080px);
  //font-family: Sans-Serif;
}

.components {
  color: white;
  margin: s(50px);

  a {
    color: white;
    font-size: s(18px);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

#cmap {
  > li, a {
    color: $rb-red;
    list-style-type: none;
    margin-bottom: s(10px);
    &.focused {
      color: cyan;
    }
  }
}

#main {
  width: s(1920px);
  height: s(1080px);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;

  .videoItem {
    position: absolute;
    top: 0;
    left: 0;
    width: s(1920px);
    height: s(1080px);
    z-index: 1;
  }

  .page {
    position: absolute;
    top: 0;
    left: 0;
    width: s(1920px);
    height: s(1080px);
    z-index: 2;
  }
}

.is-low-resolution {
  *,
  *::before,
  *::after {
    animation: none !important;
    transition: none !important;
  }
}

.offscreen {
  position: absolute;
  top: -100000px;
}

#preload {
  display: none;
}

#home {
  padding-top: s(100px);
}

.clear {
  clear: both;
}

*:focus {
  outline: none;
}

.playlist__list,
.schedule-list__list-wrapper,
.grid-panel-cardsWrapper {
  &::before,
  &::after {
    content: "";
    display: table;
    width: 100%;
  }

  &::after {
    clear: both;
  }

  .list__item {
    position: relative;
    display: block;
    float: left;
  }
}

.list__item {
  display: inline-block;
  position: relative;
}

@keyframes blink-fg { 50% { color: transparent; text-shadow: none; } }
@keyframes blink-bg { 50% { background: none; } }
@keyframes blink-fgbg { 50% { color: transparent; text-shadow: none; background: none; } }
@-webkit-keyframes blink-fg { 50% { color: transparent; text-shadow: none; } }
@-webkit-keyframes blink-bg { 50% { background: none; } }
@-webkit-keyframes blink-fgbg { 50% { color: transparent; text-shadow: none; background: none; } }

#splash-screen {
  width: s(1920px);
  height: s(1080px);
  position: absolute;
  top: 0px; left:0px;
  z-index:99;
}

#hbbtv-api {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  visibility: hidden;
}
