.card--featured.card {
  height: $featured-card-height;
  width: $featured-card-width;

  .card__inner {
    height: $featured-card-height;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: $card-offset-y;
      right: $card-offset-x;
      bottom: $card-offset-y;
      left: $card-offset-x;
      border-radius: $card-border-radius;
      background-image: linear-gradient(180deg, transparent,rgba($rb-blue-charcoal, 0.4));
      background-image: -webkit-linear-gradient(270deg, transparent, rgba($rb-blue-charcoal, 0.4));
      transition: $card-transition;
      // will-change: top, right, bottom, left;
    }

    &__thumb {
      .image-wrapper {
        width: $featured-card-width;
        height: $featured-card-height;
      }

      > video {
        width: $featured-card-width;
        height: $featured-card-height;
      }
    }

    &__details {
      min-height: auto;
      text-align: center;
      padding: s(50px);

      &::before {
        display: none;
      }

      &__title {
        position: relative;
        margin-top: s(12px);

        img {
          display: block;
          position: absolute;
          bottom: s(8px);
          left: 50%;
          transform: translateX(-50%);
          transition: $card-transition;
          opacity: 0;
          // will-change: opacity;
          z-index: 1;
        }
      }

      h3 {
        font-size: s(40px);
        line-height: s(48px);
        color: $rb_white;
        transition: $card-transition;
        // will-change: color, opacity;
      }

      h4 {
        color: $rb_white;
        transition: $card-transition;
        // will-change: color;
      }

      > .card-progress-bar {
        top: auto;
        bottom: 0;
        border-bottom-right-radius: $card-border-radius;
        border-bottom-left-radius: $card-border-radius;
      }

      > .card-panel {
        position: static;
        display: inline-block;
        margin-bottom: s(8px);
      }
    }
  }

  &.title-treatment-loaded {
    .card__inner {
      &__details {
        &__title {
          img {
            opacity: 1;
          }
        }

        h3 {
          opacity: 0;
        }
      }
    }
  }

  &.is-focused {
    .card__inner {
      &::before {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      &__details {
        h3 {
          color: $rb-alabaster;
        }

        h4 {
          color: $rb-alabaster;
        }
      }
    }
  }
}
