
.contextual {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  opacity: 1;

  background: rgba(0, 0, 0, 0.7);

  &.has-tabs {
    background: transparent;
    opacity: 1;
  }

  // TODO: This is a hack to get the videos displaying horizontally. Remove at will.
  .video-card {
    float: left;
    &.is-focused {
      margin-top: s(-3px);
    }
  }
  .page-card {
    float: left;
  }
  .universal-card {
    float: left;
  }

}
