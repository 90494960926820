.card {
  position: relative;
  height: $card-height;
  width: $card-width;
  float: left;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  &__inner {
    position: relative;
    height: $card-thumb-height;

    &__thumb {
      position: absolute;
      width: auto;
      height: auto;
      top: $card-offset-y;
      right: $card-offset-x;
      left: $card-offset-x;
      bottom: $card-offset-y;
      transition: $card-transition;
      border-radius: $card-border-radius;
      perspective: 1px;
      overflow: hidden;
      // will-change: top, right, bottom, left;
      z-index: 0;

      .image-wrapper {
        display: block;
        position: absolute;
        top: $card-offset-y * -1;
        left: $card-offset-x * -1;
        width: $card-width;
        height: $card-thumb-height;
        transition: $card-transition;
        background-color: $pure-black;
        // will-change: top, left, background-color;

        &--loading {
          background-color: rgba($rb-grey-100, 0.8);
        }

        img {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transition: $card-transition;
          opacity: 0.5;
          // object-fit: cover;
          // object-position: center;
          // will-change: opacity;
        }
      }

      > video {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: $card-width;
        height: $card-thumb-height;
        // object-fit: cover;
        z-index: 7;
      }
    }

    &__details {
      position: absolute;
      top: auto;
      left: 0;
      bottom: $card-offset-y;
      width: 100%;
      min-height: s(105px);
      padding: s(15px) s(40px);
      transition: $card-transition;
      // will-change: padding, min-height;
      z-index: 1;

      &::before {
        content: "";
        position: absolute;
        width: auto;
        top: 0;
        right: $card-offset-x;
        bottom: 0;
        left: $card-offset-x;
        border-radius: 0 0 $card-border-radius $card-border-radius;
        background-color: $rb-grey-400;
        transition: $card-transition;
        // will-change: right, bottom, left, background-color;
        z-index: 0;
      }

      > * {
        position: relative;
        z-index: 1;
      }

      h3 {
        margin: 0;
        font-size: s(27px);
        font-weight: 600;
        line-height: s(32px);
        color: $pure-black;
      }

      h4 {
        margin: 0 0 s(8px);
        font-size: s(22px);
        font-weight: 100;
        line-height: s(32px);
        color: $pure-black;
      }

      p {
        margin: s(8px) 0 0;
        font-size: s(22px);
        font-weight: 100;
        line-height: s(30px);
        color: $pure-black;
      }

      > .card-progress-bar {
        position: absolute;
        top: s(-6px);
        left: $card-offset-x;
        right: $card-offset-x;
        width: auto;
        transition: $card-transition;
        // will-change: left, right;

        .card-progress-bar__inner {
          background-color: $rb-red;
        }
      }
    }
  }

  &.is-focused &__inner {
    &__thumb {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      .image-wrapper {
        top: 0;
        right: 0;
        left: 0;

        img {
          opacity: 0.9;
        }
      }
    }

    &__details {
      padding-top: s(22px);
      padding-bottom: s(24px);
      bottom: 0;
      min-height: s(121px);

      &::before {
        right: 0;
        left: 0;
        background-color: $rb-alabaster;
      }

      > .card-progress-bar {
        right: 0;
        left: 0;
      }
    }
  }

  &.has-no-title &__inner {
    &__details {
      padding: 0;
      min-height: s(6px);

      &::before {
        display: none;
      }

      > .card-progress-bar {
        top: 0;
        border-bottom-right-radius: $card-border-radius;
        border-bottom-left-radius: $card-border-radius;
      }
    }
  }

  .fav-icon {
    position: absolute;
    top: $card-offset-y + s(16px);
    right: $card-offset-x + s(16px);
    transition: $card-transition;
    z-index: 6;
    // will-change: top, right;
  }

  &.is-focused .fav-icon {
    top: s(16px);
    right: s(16px);
  }
}
