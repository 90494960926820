.playlist-card {
  $this: &;
  position: relative;
  height: $playlist-card-height;
  width: $playlist-card-width;
  float: left;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  &__time {
    position: absolute;
    display: block;
    height: 100%;
    width: $playlist-card-time-width;
    padding-left: $playlist-card-offset-x;
    overflow: hidden;
    z-index: 1;

    .time {
      color: $rb-alabaster;
      position: absolute;
      top: s(56px);
      font-weight: 700;
    }
  }

  &__playable-icon {
    margin-right: s(12px);
  }
  
  &__inner {
    position: absolute;
    height: 100%;
    width: 100%;
    padding-left: $playlist-card-height;
    overflow: hidden;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      width: auto;
      top: $playlist-card-offset-y;
      right: $playlist-card-offset-x;
      bottom: $playlist-card-offset-y;
      left: $playlist-card-offset-x;
      border-radius: $card-border-radius;
      background-color: $rb-grey-400;
      transition: $card-transition;
      // will-change: right, bottom, left, background-color;
      z-index: -1;
    }

    &__thumb {
      position: absolute;
      top: $playlist-card-offset-y;
      right: ($playlist-card-width - $playlist-card-height) + $playlist-card-offset-y;
      bottom: $playlist-card-offset-y;
      left: $playlist-card-offset-x;
      width: auto;
      height: auto;
      border-top-left-radius: $card-border-radius;
      border-bottom-left-radius: $card-border-radius;
      transition: $card-transition;
      overflow: hidden;
      // will-change: top, right, bottom, left;
      z-index: 0;

      .image-wrapper {
        position: absolute;
        width: $playlist-card-height;
        height: $playlist-card-height;
        top: $playlist-card-offset-y * -1;
        left: $playlist-card-offset-x * -1;
        transition: $card-transition;
        background-color: $rb-grey-100;
        overflow: hidden;
        // will-change: top, left, background-color;

        &--loading {
          background-color: $rb-grey-200;
        }

        img {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transition: $card-transition;
          opacity: 0.5;
          // object-fit: cover;
          // object-position: center;
          // will-change: opacity;
        }
      }

      .overlay-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &__details {
      position: relative;
      top: ($playlist-card-height / 2) - ($playlist-card-offset-x / 2) - s(32px);
      left: $playlist-card-offset-y;
      width: $playlist-card-width - ($playlist-card-height * 1.5);
      transition: $card-transition;
      // will-change: left;

      h3 {
        margin: s(4px) 0 0;
        font-size: s(26px);
        font-weight: 600;
        line-height: s(32px);
        color: $pure-black;

        .favicon {
          vertical-align: text-bottom;
        }
      }

      h4 {
        margin: s(4px) 0 0;
        font-size: s(22px);
        font-weight: 100;
        line-height: s(32px);
        color: $pure-black;

        .blinker {
          display: inline-block;
          position: relative;
          padding-left: s(20px);
          font-weight: 600;
          line-height: inherit;
          text-transform: uppercase;
          color: $rb-red;

          &:before  {
            content: "\2022";
            color: $rb-red;
            position: absolute;
            left: 0;
            top:0;
            margin-right: s(10px);
            animation: color-blink 1s linear infinite;
          }
        }

        @keyframes color-blink {
          50% { opacity: 0.0; }
        }
      }

      > .card-panel {
        position: relative;
        bottom: auto;
        right: auto;

        .card-label {
          margin: s(4px) 0 0;
          font-size: s(19px);
          font-weight: 100;
          line-height: s(32px);
          padding: 0;
          color: $rb-grey-200;
          background-color: transparent;

          &--live {
            padding-left: s(16px);
            color: $label-bg--live;

            &::before {
              margin-top: s(-2px);
              left: 0;
              background-color: $rb-grey-200;
            }
          }

          @at-root .is-focused #{&} {
            color: $pure-black;

            &--watching {
              color: $label-bg--live;
            }

            &--live {
              color: $label-bg--live;

              &::before {
                background-color: $label-bg--live;
              }
            }

            // &--delayed {
            //   color: $rb-yellow;
            // }

            &--canceled {
              color: $rb-orange;
            }

            // &--upcoming {
            //   color: $rb-yellow;
            // }

            &--unavailable {
              color: $rb-orange;
            }
          }
        }
      }
    }
  }

  @at-root .is-servus & {
    &__inner {
      margin-left: $playlist-card-time-width;

      &::before {
        background-color: $servus-playlist-black;
      }

      &__thumb {
        .overlay-icon {
          background-color: $servus-red;
          width: s(54px);
          height: s(54px);
          border-radius: s(27px)
        }
      }

      &__details {
        h3 {
          color: $rb_white;
          transition: $card-transition;
          // will-change: color;
        }

        h4 {
          color: $rb_white;
          transition: $card-transition;
          // will-change: color;
        }
      }
    }

    &.is-focused {
      #{$this}__inner {
        &::before {
          background-color: $rb-alabaster;
        }

        &__details {
          h3 {
            color: $pure-black;
          }

          h4 {
            color: $pure-black;
          }
        }
      }
    }
  }

  &.is-focused {
    #{$this}__inner {
      &::before {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $rb-alabaster;
      }

      &__thumb {
        position: absolute;
        top: 0;
        right: $playlist-card-width - $playlist-card-height;
        bottom: 0;
        left: 0;

        .image-wrapper {
          top: 0;
          left: 0;

          img {
            opacity: 0.9;
          }
        }
      }

      &__details {
        left: $playlist-card-offset-y * 3;
      }
    }
  }
}
