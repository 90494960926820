.tab-container {
  position: relative;
  width: auto;
  padding: 0 s(184px);
  background: rgba(0,6,12, .7);
  z-index: -1;

  .full-screen {
    padding-bottom: s(1000px);
  }
}

.tab__info {
  padding-top: s(140px);
  padding-bottom: s(56px);
  margin-left: s(16px);
  min-height: s(370px);
}

.tab__text {
  display: block;
}

.tab__title {
  line-height: 1;
  font-weight: 700;
  font-size: $contextual-title;
  color: $rb-alabaster;
  &:empty {
    display: none;
  }
}

.tab__subtitle {
  margin: $gutter 0 0 0;
  font-weight: 100;
  font-size: s(30px);
  line-height: s(47px);
  color: $rb-alabaster;

  &:empty {
    display: none;
  }
}

.tab__desc {
  margin: s(16px) 0 0 0;
  max-width: s(1300px);
  font-size: s(30px) !important;
  color: $rb-alabaster;
  font-weight: 100;
  line-height: s(47px) !important;
  @include desc;

  &:empty {
    display: none;
  }
}

.tab__buttons {
    margin-top: s(56px);
    .list__items {
        margin-top: 0px !important;
        .list__item {
            margin-left: s(10px);
        }
    }

}
