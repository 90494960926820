$button-height: s(112px);
$button-width: s(747px);

$small-button-height: s(80px);
$small-button-width: s(672px);

.regular-button {
  position: relative;
  padding: s(4px) s(10px);
  width: $button-width;

  &::before {
    content: "";
    position: absolute;
    top: s(2px);
    right: s(5px);
    bottom: s(2px);
    left: s(5px);
    background-color: $rb-new-back;
    border-radius: $card-border-radius;
    transition: top $animation-time--fast $animation-curve,
      right $animation-time--fast $animation-curve,
      left $animation-time--fast $animation-curve,
      bottom $animation-time--fast $animation-curve;
    z-index: 0;
    // will-change: background-color, top, right, bottom, left;
  }

  &.is-focused {
    &::before {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $rb-red;
    }

    .regular-button__inner {
      color: $rb-alabaster;
    }
  }

  &.is-block {
    &::before {
      background-color: $rb-new-back;
    }

    .regular-button__inner {
      text-align: center;
      color: $rb-alabaster;
    }

    &.is-focused {
      &::before {
        background-color: $rb-red;
      }
    }
  }

  &--small {
    width: $small-button-width;
    padding: s(10px);

    &::before {
      top: s(5px);
      right: s(5px);
      bottom: s(5px);
      left: s(5px);
    }
  }

  &__inner {
    line-height: $button-height;
    color: $rb-iron;
    text-align: center;
    font-size: s(26px);
    font-family: rb-bold;
    text-transform: uppercase;
    position: relative;
    padding: 0 s(32px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    z-index: 1;

    &--small {
      line-height: $small-button-height;
    }

    &--switch {
      text-align: left;
      padding-right: s(112px) !important;

      .regular-button__icon {
        border-radius: 50%;
      }
    }

    &--with-value {
      text-align: left;
      padding-right: s(200px) !important;
    }
  }

  &__icon {
    float: right;
    position: absolute;
    top: ($button-height - s(56px)) / 2;
    right: s(24px);
    height: s(56px) !important;
    width: s(56px) !important;
  }

  &__value {
    float: right;
    position: absolute;
    top: ($button-height - s(56px)) / 2;
    right: s(80px);
    height: s(56px);
    line-height: s(56px);
    text-align: right;
    font-family: rb-bold;
  }
}

.link-button {
  display: inline-block;
  text-decoration: underline;
  padding: s(20px) 0;
  border-radius: s(8px);
  &.is-focused {
    padding-right: s(40px);
    padding-left: s(40px);
    background-color: $white-faded;
  }
}

.view-more-button {
  $this: &;
  position: relative;
  width: $card-width;
  height: $card-height;
  color: $rb-grey-400;
  text-align: center;
  text-transform: uppercase;
  // will-change: color;
  // background-color: rgba(rgb(166, 255, 0), 0.4);

  &__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &__icon {
      position: relative;
      width: s(80px);
      height: s(80px);
      border-radius: s(40px);
      margin: 0 auto s(8px);
      transition: $card-transition;
      // will-change: margin-bottom;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $rb-new-back;
        border-radius: s(40px);
        transition: $card-transition;
        transform: scale(0.8);
        // will-change: transform, background-color;
      }

      img {
        width: s(32px);
        height: s(32px);
        position: 'absolute';
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        opacity: 0.6;
        // will-change: opacity;
      }
    }

    &__text {
      font-weight: 600;
      font-size: s(18px);
    }
  }

  &.is-focused {
    color: $rb-white;

    #{$this}__inner {
      &__icon {
        &::before {
          transform: none;
          background-color: $rb-red;
        }

        img {
          opacity: 1;
        }
      }
    }
  }

  // So the button is centered for different row heights
  &--VideoCard {
    height: $video-card-height;
  }

  &--ChannelCard {
    height: $channel-card-height;
  }

  &--LinearChannelCard {
    height: $linear-channel-card-height;
  }
}
