.settings-captions-preview {
    width: s(760px);
    height: s(430px);
    position: absolute;
    left: s(800px);
    top: s(112px);
    background-image: $settings-captions-preview-image;
    background-size: cover;

    .captions-container {
        #captionWindow {
            bottom: s(50px) !important;
        }
    }
}
