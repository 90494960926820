#ad-countdown {
  position: absolute;
  left: s(100px);
  bottom: s(80px);
  line-height: s(36px);
  font-size: s(24px);
  font-weight: 700;
  color: $rb-alabaster;
  text-shadow: 0 s(1px) s(1px) rgba($rb-blue-charcoal, 0.5);
  pointer-events: none;
}