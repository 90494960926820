$intro-loading-duration: 3s;
#loader {
  position: absolute; top: 0; left: 0;
  width: s(1920px);
  height: s(1080px);
  background: $INTRO_BG_COLOR;
  z-index: 160;

  .pulsing{
    animation: pulsate 6s $animation-curve infinite !important;
  }

  @keyframes pulsate {
    0% {transform: scale(1, 1);}
    50% {transform: scale(1.1, 1.1);}
    100% {transform: scale(1, 1);}
  }

  > img {
    position: absolute;
    top: s(410px);
    left: 50%;
    margin-left: ($splash-screen-width / 2) * -1;
    height: $splash-screen-height;
    width: $splash-screen-width;
  }
  > div {
    position: absolute; top: s(710px); left: s(763px);
    height: s(14px); width: s(395px);
    white-space: nowrap;
    font-family: none;
    > div {
      width: s(73px);
      display: inline-block;
      height: s(10px);
      margin-right: s(10px);
      margin-top: 2px;
      background: $low-fps-into-loader;
      transform: scale(1);
      &.active {
        transform: scale(1,2.2);
        background-color: $low-fps-into-loader--active;
     }
    }
  }
}
