.language-tab-items-wrapper {
  overflow-x: hidden;
  text-align: center;
  white-space: nowrap;
  margin: 0 s(75px);
  padding-top: s(35px);
  width: 99%;
}
.language-tab__items {
  position: relative;
  margin-bottom: $tab-items-margin;
}

.language-tab__item {
  position: relative;
  display: inline-block;
  margin: 0 s(20px) 0 s(10px);
  height: s(65px);
  padding-left: s(15px);
  padding-right: s(15px);
  line-height: s(56px);

  color: $rb-grey-300;
  text-align: center;
  font-size: s(36px);
  white-space: nowrap;
  vertical-align: middle;
  font-family: rb-regular;
  background-color: none;
  font-weight: normal;

  &.is-focused {
    background-color: $pure-white;
    color: $rb-black;
    font-family: rb-regular;
    border-radius: s(42px);
  }

  &.is-selected {
    color: $pure-white;
  }

  &.is-focused.is-selected {
    color: $rb-black;
  }

  .language-tab__item-check {
    width: s(28px);
    margin-right: s(10px);
    margin-top: s(2px);
  }

}