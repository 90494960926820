.channel-title {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: opacity .8s ease-in-out;
  background: rgba(0, 6, 12, .2);

  &.shown {
    opacity: 1;
  }

  &.hidden {
    opacity: 0;
  }

  .channel-title-container {
    position: absolute;
    height: s(144px);
    bottom: s(104px);
    left: s(104px);
    display: flex;
    display: -webkit-flex;
  }

  .channel-image {
    height: s(144px);
    width: s(144px);
    border-radius: s(72px);
    border: solid 4px $rb-alabaster;
  }

  .channel-text-wrapper {
    float:right;
    margin-left: s(40px);
  }

  h1 {
    font-weight: 900;
    font-size: s(68px);
    height: s(90px);
    line-height: s(78px);
    color: $rb-alabaster;
    margin: 0; 
  }

  span {
    font-size: s(38px);
    line-height: s(48px);
    height: s(48px);
    color: $rb-alabaster;
  }

}